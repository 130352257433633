import { Module } from "@csapi/provisioning/api";

interface UserModule {
    module_id?: string;
    enabled?: boolean;
    role?: Array<string>;
}

interface Encrypted {
    iv: string,
    content: string
}

export interface UserData {
    id: Encrypted,
    merchant_id?: string,
    role: UserRoleEnum,
    username: string,
    firstname?: string,
    lastname?: string,
    companyname?: string,
    shortIdentifier?: string,
    appToken: Encrypted,
    csapiAuthToken: Encrypted,
    csapiUrl: string,
    applicationModules?: UserModule[],
    defaultLang?: string,
    defaultTaxRate?: number,
    modules?: Module[]
}

export enum UserRoleEnum {
    BASE="BASE",
    SUPPORT="SUPPORT",
    PACKAGER="PACKAGER",
}