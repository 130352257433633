import Localization from "@localization/Index"
import { CSSProperties } from "react"
import { OptionProps, SingleValueProps } from "react-select"
import CSReactSelect, { CSOption } from "./CSReactSelect"
import FlagsIcon from "@components/FlagsIcon/FlagsIcon"
import { AddressCountryCodeEnum, CountryToCurrencyMap, LanguageId } from '@interfaces/Icons';


type Props = {
    mode: 'LANGUAGE' | 'COUNTRY' | 'CURRENCY'
    width?: number,
    hasError?: boolean, 
    errorText?: string, 
    value?: CSOption|null,
    onChange: Function, 
    controlId: string, 
    isOptionDisabled?: (option: unknown) => boolean, 
    label?: string,
    className?: string
}



const getOptions = (mode: Props['mode']): CSOption[] => {
    switch (mode) {
        case 'LANGUAGE':
            return Object.entries(LanguageId).map(([key, value]) => {
                let upperCaseLanguageId = key.toUpperCase() as keyof typeof LanguageId
                return {
                    value: key,
                    label: Localization.LANGUAGES[upperCaseLanguageId] ?? '',
                };
            });
        case 'COUNTRY':
            return Object.entries(AddressCountryCodeEnum).map(([key, value]) => {
                return {
                    value: key,
                    label: Localization.COUNTRIES_CODE[value as keyof typeof Localization.COUNTRIES_CODE] ?? '',
                }
            })
        case 'CURRENCY':
            return Object.entries(CountryToCurrencyMap).map(([key, value]) => {
                return {
                    value: value,
                    label: Localization.CURRENCY[value as keyof typeof Localization.CURRENCY] ?? '',
                }
            })
        default:
            return [];
    }
}

const LanguageOptions = (props: OptionProps<unknown>) => {
    const {data,children, className,cx,getStyles, isDisabled,isFocused,isSelected,innerRef,innerProps,} = props
    const { value } = data as CSOption
    
    return (
        <div
            ref={innerRef}
            style={getStyles('option', props) as CSSProperties}
            className={`d-flex justify-content-start align-items-start ${cx(
            {
                option: true,
                'option--is-disabled': isDisabled,
                'option--is-focused': isFocused,
                'option--is-selected': isSelected,
            },
            className
            )}`}
            {...innerProps}>
            <FlagsIcon flagId={LanguageId[value as keyof typeof LanguageId]} size={20} title={true} round={true}></FlagsIcon>
            <span className="ms-2">{children}</span>
        </div>
    )
}

const CountryOptions = (props: OptionProps<unknown>) => {
    const {data,children, className,cx,getStyles, isDisabled,isFocused,isSelected,innerRef,innerProps,} = props
    const { value } = data as CSOption
    
    return (
        <div
            ref={innerRef}
            style={getStyles('option', props) as CSSProperties}
            className={`d-flex justify-content-start align-items-start ${cx(
            {
                option: true,
                'option--is-disabled': isDisabled,
                'option--is-focused': isFocused,
                'option--is-selected': isSelected,
            },
            className
            )}`}
            {...innerProps}>
            <FlagsIcon flagId={AddressCountryCodeEnum[value as keyof typeof AddressCountryCodeEnum].toLowerCase()} size={20} round={true}></FlagsIcon>
            <span className="ms-2">{children}</span>
        </div>
    )
}

const CurrencyOptions = (props: OptionProps<unknown>) => {
    const {data,children, className,cx,getStyles, isDisabled,isFocused,isSelected,innerRef,innerProps,} = props
    const { value } = data as CSOption

    const flagId = Object.keys(CountryToCurrencyMap)[Object.values(CountryToCurrencyMap).indexOf(value)]

    return (
        <div
            ref={innerRef}
            style={getStyles('option', props) as CSSProperties}
            className={`d-flex justify-content-start align-items-start ${cx(
            {
                option: true,
                'option--is-disabled': isDisabled,
                'option--is-focused': isFocused,
                'option--is-selected': isSelected,
            },
            className
            )}`}
            {...innerProps}>
            <FlagsIcon flagId={flagId.toLowerCase()} size={20} title={true} round={true}></FlagsIcon>
            <span className="ms-2">{children}</span>
        </div>
    )
}

const LanguageSingleValue = (props: SingleValueProps<unknown>) => {
    const { data, children, className, cx, getStyles, isDisabled, innerProps } = props;
    const { value } = data as CSOption
    return (
        <div style={getStyles('singleValue', props) as CSSProperties} 
        className={cx({ option: true, 'option--is-disabled': isDisabled },"d-flex align-items-center justify-content-start", className)} {...innerProps}>
            <FlagsIcon flagId={LanguageId[value as keyof typeof LanguageId]} size={23} title={true} round={true}></FlagsIcon>
            <span className="ms-2">{children}</span>
        </div>
    )
}

const CountrySingleValue = (props: SingleValueProps<unknown>) => {
    const { data, children, className, cx, getStyles, isDisabled, innerProps } = props;
    const { value } = data as CSOption

    const formatCountryCode = (code: string) => { return code.charAt(0).toUpperCase() + code.slice(1).toLowerCase()}
    const formattedValue = typeof value === 'string' ? formatCountryCode(value) : ''

    return (
        <div style={getStyles('singleValue', props) as CSSProperties} 
        className={cx({ option: true, 'option--is-disabled': isDisabled },"d-flex align-items-center justify-content-start" , className)} {...innerProps}>
            <FlagsIcon flagId={AddressCountryCodeEnum[formattedValue as keyof typeof AddressCountryCodeEnum].toLowerCase()} size={23} round={true}></FlagsIcon>
            <span className="p-2">{children}</span>
        </div>
    )
}


const CurrencySingleValue = (props: SingleValueProps<unknown>) => {
    const { data, className, cx, getStyles, isDisabled, innerProps } = props;
    const { value } = data as CSOption

    const currency = Object.entries(CountryToCurrencyMap).find(([key, cur]) => cur === value)

    return (
        <div style={getStyles('singleValue', props) as CSSProperties} 
        className={cx({ option: true, 'option--is-disabled': isDisabled },"d-flex align-items-center justify-content-start" , className)} {...innerProps}>
            <FlagsIcon flagId={currency? currency[0].toLowerCase(): 'eu'} size={23} round={true}></FlagsIcon>
            <span className="p-2">{Localization.CURRENCY[value as keyof typeof Localization.CURRENCY] ?? ''}</span>
        </div>
    )
}


const selectOptionComponent = (mode: Props['mode']) => {
    switch (mode) {
        case 'LANGUAGE':
            return LanguageOptions
        case 'COUNTRY':
            return CountryOptions
        case 'CURRENCY':
            return CurrencyOptions
        default:
            return CountryOptions
    }
}

const selectSingleValueComponent = (mode: Props['mode']) => {
    switch (mode) {
        case 'LANGUAGE':
            return LanguageSingleValue
        case 'COUNTRY':
            return CountrySingleValue
        case 'CURRENCY':
            return CurrencySingleValue
        default:
            return CountrySingleValue
    }
}


const CSFlagsSelect = (props: Props)=>{
    const { width, hasError, errorText, value, onChange, controlId, isOptionDisabled, label, mode, className } = props
    const options = getOptions(mode)
    const OptionComponent = selectOptionComponent(mode)
    const SingleValueComponent = selectSingleValueComponent(mode)
    
    const components = {
        Option: OptionComponent,
        SingleValue: SingleValueComponent
    }

    return (
        <CSReactSelect
            className={className}
            width={width}
            hasError={hasError}
            errorText={errorText}
            client 
            value={value}
            onChange={onChange}
            controlId={controlId} 
            options={options}
            isOptionDisabled={isOptionDisabled}
            label={label}
            components={components}
            styles={{
                option: (base) => ({...base}),
                singleValue: (base) => ({...base}),
            }}
        />
    )
}

export default CSFlagsSelect