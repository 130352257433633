import { getCustomer, resetCustomerPwd, updateCustomerStatus, updateCustomer as updateCustomerApi } from '@api/Customers'
import { Customer, EntitySingleResponseCustomer } from '@csapi/provisioning'
import Localization from '@localization/Index'
import { askConfirmation, closeWaiting, showWaiting, toastError, toastSuccess } from '@utils/NotificationsUtils'
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { useParams } from 'react-router-dom'

enum PageState {
    LOADING = "LOADING",
    IDLE = "IDLE",
    ERROR = "ERROR",
    NOT_FOUND = "NOT_FOUND",
}

type CustomerContextType = {
    customer: Customer | undefined
    customerEntity : EntitySingleResponseCustomer |undefined
    pageState: PageState
    loadCustomer: () => Promise<void>
    resetPassword: () => Promise<void>
    changeStatus: () => Promise<void>
    updateCustomer: (updatedCustomer: Customer) => Promise<void> 
}

const CustomerContext = createContext<CustomerContextType | undefined>(undefined)

export const CustomerProvider = ({ children }: { children: ReactNode }) => {
    const { id } = useParams<{ id: string }>()
    const [pageState, setPageState] = useState<PageState>(PageState.LOADING)
    const [customer, setCustomer] = useState<Customer | undefined>(undefined)
    const [customerEntity, setCustomerEntity] = useState<EntitySingleResponseCustomer | undefined>(undefined)

    const loadCustomer = async () => {
        setPageState(PageState.LOADING)
        try {
        if (!id) {
            setPageState(PageState.ERROR)
            return
        }
        const _customer = await getCustomer(id)
        if (!_customer) {
            setPageState(PageState.NOT_FOUND)
        } else {
            setCustomerEntity(_customer)
            setCustomer(_customer.content)
            setPageState(PageState.IDLE)
        }
        } catch (e) {
        console.error(e)
        setPageState(PageState.ERROR)
        }
    }

    const resetPassword = async () => {
        if (!customer) return
            const confirmResponse = await askConfirmation({
            title: <p>{Localization.PAGE_CUSTOMERS.TABS.PWD_MAN.RESET_PWD_MESSAGE.replace("__CUSTOMER__", `${customer.first_name} ${customer.last_name}`)}</p>
        })
        if (!confirmResponse.isConfirmed) return
        try {
            showWaiting(Localization.ATTENDERE)
            if (customer.security?.username) {
                await resetCustomerPwd(customer.security.username)
                toastSuccess(Localization.PAGE_CUSTOMERS.RESET_PWD_SUCCESS)
            } else {
                toastError(Localization.PAGE_CUSTOMERS.TABS.PWD_MAN.NO_USERNAME)
            }

        closeWaiting()

        } catch (e) {
            console.error(e)
            closeWaiting()
            toastError(Localization.ERRORE_GENERICO)
        }
    }

    const changeStatus = async () => {
        if (!customer) return
        const confirmResponse = await askConfirmation({
            title: <p>{Localization.PAGE_CUSTOMERS.TABS.PWD_MAN.CHANGE_STATUS_MESSAGE.replace("__STATUS__", `${customer.status === 'ACTIVE' ? Localization.PAGE_CUSTOMERS.STATUS.INACTIVE : Localization.PAGE_CUSTOMERS.STATUS.ACTIVE}`)}</p>
        })
        if (!confirmResponse.isConfirmed) return
        try {
            showWaiting(Localization.ATTENDERE)
            await updateCustomerStatus(customer._id?.$oid!, customer.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')
            
            loadCustomer()
            toastSuccess(Localization.PAGE_CUSTOMERS.CHANGE_STATUS_SUCCESS)
            closeWaiting()
        } catch (e) {
            console.error(e)
            closeWaiting()
            toastError(Localization.ERRORE_GENERICO)
        }
    }
    
    
    const updateCustomer= async (updatedCusomer :Customer) =>{
        if (!updatedCusomer) return
    
        try {
            const customerResponse :EntitySingleResponseCustomer =  await updateCustomerApi(customer?._id?.$oid!, updatedCusomer)
            setCustomer(customerResponse?.content)
            setCustomerEntity(customerResponse)
            setPageState(PageState.IDLE)
            closeWaiting()
            toastSuccess(Localization.PAGE_CUSTOMERS.OPERATION_SUCCESS)
    
        } catch (error) {
            closeWaiting()
            setPageState(PageState.IDLE)
            toastError(Localization.PAGE_CUSTOMERS.OPERATION_ERROR) 
            console.error('Error updating or creating warehouse:', error)
    
        }
    }
    
    



    useEffect(() => {
        loadCustomer()
    }, [id])

    return (
        <CustomerContext.Provider value={{ customer,customerEntity, pageState, loadCustomer, resetPassword, changeStatus, updateCustomer }}>
        {children}
        </CustomerContext.Provider>
    )
}

export const useCustomerContext = () => {
    const context = useContext(CustomerContext)
    if (!context) {
        throw new Error('useCustomerContext must be used within a CustomerProvider')
    }
    return context
    }
