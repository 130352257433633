import styles from './css/OrderItemsEntry.module.scss'
import { OrderItemWithInfo } from "@interfaces/Orders"
import { useNavigate } from 'react-router-dom'
import ItemHeader from '../ItemHeader/ItemHeader'

type Props = {
    loading?: boolean,
    item?: OrderItemWithInfo,
    link?: boolean
}

const OrderItemsEntry = (props:Props)=>{
    const { loading, item } = props

    if(loading) return (
        <div className="m-2 p-2 d-flex flex-column loading-skeleton">
            <div className={styles.skeletonItem} style={{width:"200px"}}>&nbsp;</div>
            <div className={styles.skeletonItemSub} style={{width:"300px"}}>&nbsp;</div>
        </div>
    )

    const { qty, cost, variation, variationType, unit_price } = item!

    return (
        <div className={`row ${styles.container}`}>
            <div className="col-10">
                <ItemHeader item={item} /> 
            </div>
            <div className={`col-md-2 d-flex justify-content-end align-items-center ${styles.cell}`}>
                {qty}
            </div>
            <div className={`col-md-4 d-flex justify-content-end align-items-center ${styles.cell}`}>
            {unit_price?.amount?`${unit_price?.amount?.toFixed(2)} ${unit_price?.currency ?? "EUR"}`:"N.D."}
            </div>
            <div className={`col-md-4 d-flex justify-content-end align-items-center ${styles.cell}`}>
                <div className='d-flex flex-column justify-content-center align-items-end'>
                    <div>
                        {variation?`${variation}%`:`N.D.`}
                    </div>
                    {variationType && <div className={styles.subCell}>
                        {variationType}
                    </div>}
                </div>
            </div>
            <div className={`col-md-4 d-flex justify-content-end align-items-center ${styles.cell}`}>
                {cost?.amount?`${cost?.amount?.toFixed(2)} ${cost?.currency}`:"N.D."}
            </div>
        </div>
    )
}

export default OrderItemsEntry