import CSDiv from "@components/HTMLElements/CSDiv"
import { OrderItemWithInfo } from "@interfaces/Orders"
import Localization from "@localization/Index"
import OrderItemsEntry from "./OrderItemsEntry"
import styles from './css/OrderItemsSummary.module.scss'
import { Cost, Order } from "@csapi/provisioning"
import { Item } from '@csapi/provisioning/api'
import { useContext, useEffect, useState } from "react"
import { getItemsByIdList } from "@api/Items"
import { AuthContext } from "@providers/AuthProvider"
import { DEFAULT_LANG } from "@utils/Constants"
import OrderItemsSubtotal from "./OrderItemsSubtotal"
import OrderItemsTotal from "./OrderItemsTotal"
import { NotFoundItemInArray } from "../../../../src/interfaces/Items"
import { getOrderItemsWithInfo } from "@utils/OrderUtils"

type Props = {
    order: Order
}

const OrderItemsSummary = (props: Props) =>{
    const { order } = props
    const [componentState, setComponentState] = useState("LOADING")
    const [orderItems, setOrderItems] = useState<OrderItemWithInfo[]>()
    const auth = useContext(AuthContext)

    const loading = componentState === "LOADING"
    const error = componentState === "ERROR"

    const loadOrderItems = async()=>{
        setComponentState("LOADING")
        if(!order.items || order.items.length === 0) {
            setOrderItems([])
            return setComponentState("IDLE")
        }

        try {
            const _orderItems = await getOrderItemsWithInfo(order, auth.userData?.defaultLang ?? DEFAULT_LANG)
            setOrderItems(_orderItems)
            setComponentState("IDLE")
        } catch(err) {
            console.log(err)
            setComponentState("ERROR")
        }
    }

    useEffect(()=>{
        loadOrderItems()
    },[order])

    const { shipping_info, price_adjustments, tot_amount } = order
    const shipmentFeesValue = shipping_info?.price_tax_incl?.amount ?? 0
    const shipmentFees = shipmentFeesValue && shipmentFeesValue>0?`${shipmentFeesValue.toFixed(2)} ${shipping_info?.price_tax_incl?.currency ?? "EUR"}`:undefined
    const discountValue = price_adjustments?.[0]?.adj_with_tax ?? 0
    const discount = discountValue && discountValue>0?`${discountValue.toFixed(2)} ${tot_amount?.currency ?? "EUR"}`:undefined
    const discountLabel = price_adjustments?.[0].reason_id!==undefined?(price_adjustments?.[0].reason_id==="MARKUP"?Localization.PAGE_ORDER.MARKUP:Localization.PAGE_ORDER.SCONTO):undefined
    let subtotalNumber = tot_amount?.amount ?? 0
    if(discountValue) {
        if(discountLabel==="MARKUP") {
            subtotalNumber = subtotalNumber - discountValue
        } else {
            subtotalNumber = subtotalNumber + discountValue
        }
    }
    if(shipmentFeesValue) {
        subtotalNumber = subtotalNumber - shipmentFeesValue
    }

    const subtotal = `${subtotalNumber.toFixed(2)} ${tot_amount?.currency ?? "EUR"}`
    const total = `${tot_amount?.amount?.toFixed(2)} ${tot_amount?.currency ?? "EUR"}`
    return <>
        {!loading && orderItems?.length===0 &&
                    <p>{Localization.PAGE_ORDER.NESSUN_ARTICOLO_PRESENTE}</p>
                }
                {!loading && !orderItems &&
                    <div className="mt-4">{Localization.ERRORE_GENERICO}</div>
                }
                {orderItems && orderItems.length>0 && <>
                    <div className={`row ${styles.headerContainer}`}>
                        <div className={`col-md-10 text-start ${styles.header}`}>{Localization.PAGE_ORDER.ARTICOLO.toUpperCase()}</div>
                        <div className={`col-md-2 text-end ${styles.header}`}>{Localization.PAGE_ORDER.QUANTITA.toUpperCase()}</div>
                        <div className={`col-md-4 text-end ${styles.header}`}>{Localization.PAGE_ORDER.PREZZO_UNITARIO.toUpperCase()}</div>
                        <div className={`col-md-4 text-end ${styles.header}`}>{`${Localization.PAGE_ORDER.SCONTO} / ${Localization.PAGE_ORDER.MARKUP}`.toUpperCase()}</div>
                        <div className={`col-md-4 text-end ${styles.header}`}>{Localization.PAGE_ORDER.PREZZO_TOTALE.toUpperCase()}</div>
                    </div>
                    <CSDiv>
                        {loading && <>
                        <OrderItemsEntry loading />
                        <OrderItemsEntry loading />
                        <OrderItemsEntry loading />
                        </>}
                        
                        {!loading && <>
                            {orderItems.map((item, index)=>{
                                return <OrderItemsEntry key={`order-item-${index}`} item={item} />
                            })}
                            <OrderItemsSubtotal label={Localization.PAGE_ORDER.SUBTOTALE} value={subtotal} />
                            {shipmentFees && <OrderItemsSubtotal label={Localization.PAGE_ORDER.SPESE_DI_SPEDIZIONE} value={shipmentFees} />}
                            {discount && discountLabel && <OrderItemsSubtotal label={discountLabel} value={discount} />}
                            <OrderItemsTotal label={Localization.PAGE_ORDER.TOTALE_ORDINE} value={total} />
                        </>}
                        
                    </CSDiv>
                    </>
                }
    </>
}

export default OrderItemsSummary