import { getStatistics } from "@api/Alerts"
import { useState } from "react"
import { useEffect } from "react"
import { LinkContainer } from "react-router-bootstrap"

type Props = {

}

const MAX_UNREAD_COUNT = 99

const HeaderAlert = (_:Props) =>{

    const [unreadCount, setUnreadCount] = useState<number>(0)

    const doGetStatistics = async()=>{
        try {
            const statistics = await getStatistics()
            const totalUnread = statistics.reduce((prev, current)=> prev+(current.unread ?? 0), 0)
            setUnreadCount(totalUnread)
        } catch(err) {
            setUnreadCount(0)
        }
    }

    useEffect(()=>{
        doGetStatistics()
    },[])

    return (
        <LinkContainer to="/alerts">
            <a className="position-relative pe-3 cs-link" href="/alerts">
                <i className="bi-exclamation-triangle text-muted" style={{fontSize: '1.75rem'}} />
                {unreadCount>0 &&
                    <span className="position-absolute badge rounded-pill bg-danger" style={{zIndex: 9999, top:'-6px', left: '6px'}}>
                        {unreadCount<=MAX_UNREAD_COUNT && `${unreadCount}`}
                        {unreadCount>MAX_UNREAD_COUNT && `${MAX_UNREAD_COUNT}+`}
                    </span>
                }
            </a>
        </LinkContainer>
    )
}

export default HeaderAlert