import { AlertStatistics } from "@csapi/provisioning/api"
import axios, { AxiosResponse } from "axios"

const getStatistics = async ():Promise<AlertStatistics[]> =>{
    try {
        const response:AxiosResponse<AlertStatistics[],any> = await axios.get('api/alerts/statistics')
        return response.data
    } catch(error:any) {
        throw error
    }
}

export { getStatistics }