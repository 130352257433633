import { ElementRef, useContext, useEffect, useRef, useState } from 'react'
import styles from './css/AdvancedFilters.module.scss'
import { FiltersContext, FiltersStatus } from "@providers/FiltersProvider"
import Tooltip from 'react-bootstrap/esm/Tooltip'
import Localization from '@localization/Index'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import { getEmptyFilters, RenderFilter, GroupFiltersValueType, CurrentFilters, CurrentFiltersValueType, PresetAdvancedFilters, renderFiltersDescription, FilterTypeDefinition, FilterOptions, FilterType, DatePickerFilter, getDefaultFilters } from '@utils/AdvancedFiltersUtils'
import Container from 'react-bootstrap/esm/Container'
import { getLocalStorageItem, setLocalStorageItem } from '@utils/LocalStorage'
import React from 'react'
import { ConfigurationContext } from '@providers/ConfigurationProvider'
import { generateId } from '@utils/StringUtils'
import { endOfDay, startOfDay } from 'date-fns'
import SectionGroup from '@components/SectionGroup/SectionGroup'
import CSInput from '@components/Form/CSInput'
import SectionSubGroup from '@components/SectionGroup/SectionSubGroup'


type AdvancedFiltersProps = {
    availableFilters: Array<FilterTypeDefinition>,
    name: string,
    data_cy?:string,
    onApply: ()=>void
}

const AdvancedFilters = (props: AdvancedFiltersProps) =>{
    const [filtersPaddingBottom, setFiltersPaddingBottom] = useState(36)
    const filtersContext = useContext(FiltersContext)
    const savePresetNameRef = useRef<ElementRef<typeof CSInput>>(null)
    const [visibleChildren, setVisibleChildren] = useState(true)

    const { open, setOpen, applied, setApplied, currentFilters, setCurrentFilters, filtersStatus, setFiltersStatus, selectedPresetId, setSelectedPresetId } = filtersContext
    const { availableFilters, name, data_cy, onApply } = props

    const presetName = `presets_${name}`
    
    const [filters, setFilters ] = useState<FilterOptions[]>()
    
    const configurationContext = React.useContext(ConfigurationContext)
    const savedPresets = (configurationContext.getValue(`presets_${name}`, []) ?? []) as PresetAdvancedFilters[]
    const filtersDescription = renderFiltersDescription(currentFilters, availableFilters, filtersStatus === FiltersStatus.ERROR)

    // loads filters options if needed
    // get the empty filters and the default filters
    // set the default filters as first load values for filters if present
    // else, set the current filters in the local storage if present
    // set the empty filters otherwise
    const loadFilters = async()=>{
        const promises: Array<Promise<FilterOptions>> = []
        availableFilters.forEach((availableFilter)=>{
            const { id, type, getFilterOptions } = availableFilter
            if(getFilterOptions) {
                promises.push(new Promise(async (resolve, reject)=>{
                    const response = await getFilterOptions()
                    const filterOptions:FilterOptions = {
                        id,
                        type,
                        value: response
                    }
                    resolve(filterOptions)
                }))
            }
        })
        if(promises.length===0) {
            setFiltersStatus(FiltersStatus.READY)
            return
        }
        try {
            const response = await Promise.all(promises)
            setFilters(response)
            
        } catch(e) {
            setFiltersStatus(FiltersStatus.ERROR)
            return
        }
    }

    

    useEffect(()=>{
        //load filters at the opening of the page
        loadFilters()
    },[])

    useEffect(()=>{
        // apply filters when loaded
        if(filters) {
            const savedFilters:CurrentFilters = getLocalStorageItem(`filters.${name}`) as CurrentFilters

            // TODO: prune filters from saved filters that are no more present in available filters

            //TODO: update saved filter values with the correct format if possible, else set default value
            
            const actualFilters: CurrentFilters = []
            const emptyFilters = getEmptyFilters(availableFilters)
            availableFilters.forEach(availableFilter => {
                // check if there is the saved filter in localstorage
                const savedFilter = savedFilters?.find(filter=>filter.id === availableFilter.id)
                if(savedFilter) {
                    // if present, apply it
                    actualFilters.push(savedFilter)
                } else {
                    // if not present:
                        // if there is a defaultValue, apply it
                        if(availableFilter.defaultValue) {
                            if(availableFilter.type == FilterType.DATEPICKER) {
                                const dateFilter = availableFilter.defaultValue as [Date | null, Date | null]
                                const startDate = dateFilter[0]!==null?startOfDay(dateFilter[0]):null
                                const endDate = dateFilter[1]!==null?endOfDay(dateFilter[1]):null
                                availableFilter.defaultValue = [startDate, endDate]
                            }
                            actualFilters.push({id: availableFilter.id, value: (availableFilter.defaultValue as GroupFiltersValueType)})
                        }
                        // else apply the empty value
                        const emptyFilter = emptyFilters.find(filter=>filter.id === availableFilter.id)
                        if(emptyFilter) {
                            actualFilters.push(emptyFilter)
                        }
                }
            })
            
            setCurrentFilters([...actualFilters])
            setApplied(true)
            setFiltersStatus(FiltersStatus.READY)
            onApply()
        }
    },[filters])

    useEffect(()=>{
        console.log("::CURRENTFILTERS", {currentFilters})
        if(applied && currentFilters) {
            applyFilters()
        }
    },[applied, currentFilters])

    useEffect(()=>{
        if(open) {
            return setVisibleChildren(open)
        }
        setTimeout(()=>setVisibleChildren(open), 300)
    }, [open])

    useEffect(()=>{
        if(visibleChildren) return
        const appliedFiltersDescriptionContainer = document.getElementById("appliedFiltersDescriptionContainer")
        if(appliedFiltersDescriptionContainer===null) return
        const { offsetHeight } = appliedFiltersDescriptionContainer
        setFiltersPaddingBottom(12+offsetHeight)
    }, [visibleChildren])

    useEffect(()=>{
        // change the filters and apply when a preset is selected
        setCurrentFilters(prev=>{
            if(!prev) return undefined
            if(!selectedPresetId) return prev
            setApplied(false)
            const selectedPreset = (structuredClone(savedPresets) as PresetAdvancedFilters[])?.find(p => p.id === selectedPresetId)
            
            if(!selectedPreset?.filters) return prev
            const updatedFilters = [...selectedPreset.filters]
            return updatedFilters
        })
    },[selectedPresetId])

    const applyFilters = ()=>{
        setLocalStorageItem(`filters.${name}`, currentFilters)
        onApply()
    }

    const closeFilters = ()=>{
        if(savePresetNameRef.current) {
            savePresetNameRef.current.value = ""
        }
        return setOpen(false)
    }

    const removePreset = (presetId: string) =>{
        const newSavedPresets = savedPresets?.filter(p => p.id !== presetId)
        configurationContext.setValue(presetName, newSavedPresets)
        if(presetId===selectedPresetId) {
            setSelectedPresetId(undefined)
        }
        return
    }

    const resetFilters = ()=>{
        const defaultFilters = getDefaultFilters(availableFilters)
        setCurrentFilters([...defaultFilters])
        setSelectedPresetId(undefined)
        setApplied(false)
    }

    const saveConfiguration = ()=>{
        console.log("::saveConfiguration")
        const presetLabel = savePresetNameRef.current?.value || Localization.ADVANCED_FILTERS.SALVA_FILTRO_PLACEHOLDER
        const presetToBeSaved:PresetAdvancedFilters = {
            id: generateId(),
            label: presetLabel,
            filters: currentFilters
        }
        savedPresets?.push(presetToBeSaved)
        configurationContext.setValue(presetName, savedPresets)
        if(savePresetNameRef.current) {
            savePresetNameRef.current.value = ""
        }
        setSelectedPresetId(presetToBeSaved.id)
    }

    const setFilter = (id: string, value: CurrentFiltersValueType)=>{
        console.log("::SETFILTER")
        const availableFilter = availableFilters.find(filter=>{
            return filter.id===id
        })
        if(!availableFilter) return false
        setApplied(false)
        setSelectedPresetId(undefined)
        setCurrentFilters((prev:CurrentFilters|undefined)=>{
            if(!prev) return [{id, value}]
            const newFilters =[...prev].filter(filter=>filter.id!==id)
            newFilters.push({id, value})
            return newFilters
        })
    }

    const tooltipComponent = <Tooltip id="tooltip" style={{position:'fixed'}} data-cy={`advanced-filters-${data_cy}-nofilter`}>{Localization.ADVANCED_FILTERS.FILTRO_NON_APPLICATO}</Tooltip>
    
    return <>
        {filtersStatus === FiltersStatus.LOADING && 
        <SectionGroup className={`${styles.filtersContainer} loading-skeleton shadow`} data-cy={`advanced-filters-${data_cy}`}></SectionGroup>
        }
        {filtersStatus !== FiltersStatus.LOADING && <>
            <SectionGroup style={{paddingBottom: `${filtersPaddingBottom}px`}} data-cy={`advanced-filters-${data_cy}`} role={!open?"button":undefined} onClick={()=>{
                        if(!open) {setOpen(true)}}
                    }>
                <div className={`${open?styles.open:''}`} >
                    <div id="appliedFiltersDescriptionContainer" style={{cursor: open?"default":"pointer"}} className={`${styles.description} ${open?styles.close:''}`}>
                        {!applied && <OverlayTrigger placement="top" overlay={tooltipComponent}><i className="me-2 bi-braces-asterisk text-primary" role="tooltip" /></OverlayTrigger>}
                        {selectedPresetId!==undefined && <strong className='text-primary'>{`${savedPresets.find(preset=>preset.id===selectedPresetId)?.label}: `}</strong>}
                        {filtersDescription}
                    </div>
                    <div className={`${styles.content} ${open?'':styles.close}`}>
                        {visibleChildren &&
                        <div className="row">
                            <div className="col-sm-24 col-md-18">
                                <div className="row">
                                    <div className="col-sm-24 col-md-18">
                                        {availableFilters.map(availableFilter=>{
                                            const { id, type, label="", renderProps, defaultValue, mandatory=false, clearable=true } = availableFilter
                                            return (
                                            <Container key={`renderFilter_${id}`} fluid className="d-flex mt-field">
                                                <RenderFilter
                                                    {...renderProps}
                                                    id={id}
                                                    type={type}
                                                    label={label}
                                                    mandatory={mandatory}
                                                    clearable={clearable}
                                                    value={currentFilters?.find(currentFilter=>currentFilter.id === id)?.value}
                                                    defaultValue={defaultValue}
                                                    options={filters?.find(filter=>filter.id === id)?.value!}
                                                    onChange={(options:CurrentFiltersValueType)=>{
                                                        if(renderProps?.onChange) return renderProps.onChange(options)
                                                        return setFilter(id, options)
                                                    }}
                                                />
                                            </Container>
                                            )
                                        })}
                                        </div>
                                    </div>
                                <div className='d-flex justify-content-start ms-2 mt-field'>
                                    <button type="button" onClick={()=>{closeFilters()}} className='m-1 btn btn-light'>{Localization.ADVANCED_FILTERS.ANNULLA}</button>
                                    <button type="button" onClick={()=>{resetFilters()}} className='m-1 btn btn-outline'>{Localization.ADVANCED_FILTERS.RESETTA}</button>
                                    <button type="button" className='m-1 btn btn-primary' onClick={()=>{setApplied(true); closeFilters();}}>{Localization.ADVANCED_FILTERS.APPLICA}</button>
                                </div>
                            </div>
                            <div className='col-sm-24 col-md-6 d-flex justify-content-between flex-column'>
                                <SectionSubGroup title={Localization.ADVANCED_FILTERS.FILTRI_PERSONALIZZATI} style={{height: '100%', marginBottom: "0px"}}>
                                    <div style={{height: '100%'}} className="d-flex flex-column justify-content-between pb-4">
                                        <div style={{maxHeight: '400px', overflowY: 'auto'}} className="mt-field">
                                            {savedPresets.length === 0 &&
                                            <div>{Localization.ADVANCED_FILTERS.NESSUN_FILTRO_PERSONALIZZATO}</div>
                                            }
                                            {savedPresets.length > 0 && savedPresets.map(preset=>{
                                                return (
                                                    <div style={{width:'100%'}} key={preset.id} role="button" className={`ps-1 d-flex justify-content-between align-items-center`}>
                                                        <span className={`me-2 ${styles.presetItem} ${preset.id === selectedPresetId ? "text-primary":""}`} onClick={()=>{
                                                        setSelectedPresetId(preset.id)
                                                    }}>{preset.label}</span>
                                                        <i className={`bi-trash font-1-5x text-danger ${styles.presetItem}`} role="button" onClick={()=>{removePreset(preset.id)}}/>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='mt-field'>
                                            <CSInput 
                                                controlId='save_filter_preset_btn'
                                                placeholder={Localization.ADVANCED_FILTERS.SALVA_FILTRO_PLACEHOLDER}
                                                label={Localization.ADVANCED_FILTERS.SALVA_FILTRO_PERSONALIZZATO}
                                                type="text"
                                                ref={savePresetNameRef}
                                                onKeyPress={(e)=>{
                                                    if(e.key==="Enter") {
                                                        saveConfiguration()
                                                    }
                                                }}
                                                width={'100%'}
                                                buttons={[
                                                    {
                                                        icon: 'bi-check-circle',
                                                        action: ()=>saveConfiguration()
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </SectionSubGroup>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </SectionGroup>
        </>
        }
    </>
}

export default AdvancedFilters